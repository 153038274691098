import Flatpickr from "stimulus-flatpickr";
import { getRequest } from "init/ajax";
import { queryString } from "init/forms";
import store from "components/template_checkout/store";
import axios from "axios";
import {withPublisher} from "mixins/withPublisher";

Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

export default class extends withPublisher(Flatpickr) {

  initialize() {
    this.tabletPortrait = 700
    this.tabletLandscape = 1024
    this.desktop = 1200
    this.clientWidth = document.documentElement.clientWidth

    this.arriveTarget = document.querySelector('.js-BookNow__check-in-date-hidden')
    this.departTarget = document.querySelector('.js-BookNow__check-out-date-hidden')

    let ratesJson = JSON.parse(
      document.querySelector('.js-booking-calendar-rates').innerHTML
    )

    this.ratesData = ratesJson

    let iconNextString = document.querySelector('.js-BookingCalendar__next-month-arrow img').outerHTML

    const defaultDates = [this.arriveTarget.value, this.departTarget.value];

    let config = {
      clickOpens: false,
      nextArrow: iconNextString,
      prevArrow: iconNextString,
      minDate: document.getElementById('reservation_lead_time').value,
      position: "auto",
      showMonths: 2,
      mode: "range",
      defaultDate: defaultDates,
      locale: {
        firstDayOfWeek: 7
      },
      disable: this.getDisabledDates()
    }


    if (document.querySelector('.ListingDetail__template')) {
      config.appendTo = document.querySelector('.BookingCalendar__scroll')
      config.inline = true
    }

    this.config = config
  }

  offscreen(el) {
    // -1 = offscreen left
    //  0 = onscreen
    //  1 = offscreen right
    let val = 0;
    let rect = el.getBoundingClientRect();
    if (rect.x < 0) {
      val = -1;
    } else if ((rect.x + rect.width) > window.innerWidth) {
      val = 1;
    }
    return val;
  }

  initDates() {
    if (this.arriveTarget.value && this.departTarget.value) {
      /* eslint-disable no-underscore-dangle */
      const fp = this.element._flatpickr;
      const arriveDate = fp.parseDate(this.arriveTarget.value, "Y-m-d");
      const departDate = fp.parseDate(this.departTarget.value, "Y-m-d");
      const selectedDates = [arriveDate, departDate];
      this.change(selectedDates, null, fp);
      if (!document.querySelector('.js-calendar-error').classList.contains('show')) {
        this.fetchRates();
      }
    }
  }

  getDisabledDates() {
    const availability = this.ratesData
    if (availability === undefined) return;
    const disabled = availability.filter(
      avail => avail.is_booked === 1
    );
    // eslint-disable-next-line consistent-return
    return disabled.map(avail => avail.date);
  }

  connect() {
    super.connect();
    window.addEventListener('fetch-rates', this.fetchRates.bind(this))
    this.initDates()
    const textNode = document.querySelector('.js-BookingCalendar__submit-text');
    if (textNode && !!textNode.dataset.submitText) {
      this.submitText = textNode.dataset.submitText;
    } else {
      this.submitText = 'Choose Dates';
    }
  }

  availableCalendarDatesElements() {
    return Array.from(
      document.querySelectorAll(
        '.flatpickr-day:not(.nextMonthDay):not(.flatpickr-disabled):not(.prevMonthDay)'
      )
    )
  }

  getDates(startDate, stopDate) {
    var dateArray = new Array();
    var currentDate = startDate;
    while (currentDate <= stopDate) {
        dateArray.push(new Date(currentDate));
        currentDate = currentDate.addDays(1);
    }
    return dateArray;
  }

  onSubscriberNotification(event) {
    super.onSubscriberNotification(event);
    if (event.detail.channel === 'datePicker') {
      const [selectedDates, dateStr, instance] = event.detail.args;
      this.fp.setDate(selectedDates)
    }
  }

  change(selectedDates, dateStr, instance) {
    this.sendNotificationToSubscribers('datePicker', selectedDates, dateStr, instance)
    selectedDates.forEach((date, index) => {
      if (index === 0) {
        let dateFormatter = this.formatDate

        Array.from(document.querySelectorAll('.js-BookNow__check-in-date-visible, .js-check-in-date-visible')).forEach((visibleCheckInElement) => {
          visibleCheckInElement.value = dateFormatter(date)
        })

        Array.from(document.querySelectorAll('.js-BookNow__check-in-date-hidden, .js-check-in-date-hidden')).forEach((hiddenCheckInElement) => {
          hiddenCheckInElement.value = instance.formatDate(date, "Y-m-d")
        })
      }

      if (index === 1) {
        let lastNightStay = new Date(date);
        let dateFormatter = this.formatDate

        Array.from(document.querySelectorAll('.js-BookNow__check-out-date-visible, .js-check-out-date-visible')).forEach((visibleCheckOutElement) => {
          visibleCheckOutElement.value = dateFormatter(lastNightStay)
        })

        Array.from(document.querySelectorAll('.js-BookNow__check-out-date-hidden, .js-check-out-date-hidden')).forEach((hiddenCheckOutElement) => {
          hiddenCheckOutElement.value = instance.formatDate(lastNightStay, "Y-m-d")
        })
      }
    })

    let bookNowButtonElement = document.querySelector('.js-BookNow__button')

    // Validate minimum night stay
    let valid = false
    let error_msg
    if (selectedDates.length === 2) {
      let dates = this.getDates(...selectedDates)
      let mins = dates.map(date => {
        let formatted = instance.formatDate(date, "Y-m-d")
        let day = this.ratesData.find(avail => avail.date === formatted)
        return day.min_night_stay
      })
      let nights = mins.length - 1
      let night_min = mins[0]
      valid = nights >= night_min
      error_msg = `Your date selection requires a ${night_min} night stay minimum. Please extend your stay.`
    }

    Array.from(document.querySelectorAll('.js-choose-dates-submit-btn')).forEach((buttonElement) => {
      buttonElement.disabled = !valid
      buttonElement.innerHTML = (valid) ? "Select Dates" : this.submitText
    })

    if (valid) {
      bookNowButtonElement.dataset.canFetchRates = true
      bookNowButtonElement.innerHTML = "Select Dates"
    } else {
      if (error_msg) {
        Array.from(document.querySelectorAll('.js-calendar-error')).forEach(errorContainer => {
          this.addToolTipNode(this.fp.daysContainer.querySelector('.endRange'), error_msg);
        })
      }
      bookNowButtonElement.dataset.canFetchRates = false
      bookNowButtonElement.dataset.bookNow = false
      bookNowButtonElement.innerHTML = this.submitText
    }
  }

  addToolTipNode (parent, msg) {
    const newDiv = document.createElement("div");
    newDiv.classList = ['date-tooltip js-date-tooltip'];
    const newContent = document.createTextNode(msg);
    newDiv.appendChild(newContent);
    parent.appendChild(newDiv);
    let offscreenClass;
    let offscreen = this.offscreen(newDiv);
    if (offscreen === -1) offscreenClass = 'offscreen-left';
    if (offscreen === 1) offscreenClass = 'offscreen-right';
    if (offscreenClass) newDiv.classList.add(offscreenClass);
  }

  closeCalendarModal(event) {
    if (event) {
      event.preventDefault();
    }

    const customEvent = new CustomEvent("close-calendar-modal", { bubbles: true });
    this.element.dispatchEvent(customEvent);
  }

  currentlyDisplayedMonths() {
    if (this.fp) {
      let monthMap = this.monthIntegerToNameMap();
      let nextMonthNumber = this.fp.currentMonth + 1

      return [monthMap[String(this.fp.currentMonth)], monthMap[String(this.nextMonthNumber())]]
    } else {
      return Array.from(
        document.querySelectorAll('.cur-month')
      ).map(monthElement => monthElement.innerText.toLowerCase().trim() )
    }
  }

  currentMonthInteger() {
    if (this.fp) {
      return this.fp.currentMonth + 1
    } else {
      return (
        parseInt(
          document.querySelector('.flatpickr-monthDropdown-months').selectedOptions[0].value
        ) + 1
      )
    }
  }

  formatDate(dateString) {
    let date = new Date(dateString);
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');

    return month + '/' + day + '/' + year;
  }

  async fetchRates() {
    let formElement  = document.querySelector('.js-CalendarModal__form-container');
    let url          = formElement.action
    let checkInDate  = formElement.querySelector('#check_in_date_modal_hidden').value
    let checkOutDate = formElement.querySelector('#check_out_date_modal_hidden').value
    let propertyId   = formElement.querySelector('#property_id').value

    let oldRequest = store.state.request || {}
    let newRequest = {
      property_id:        propertyId,
      check_in_date:      checkInDate,
      check_out_date:     checkOutDate
    }

    let request = {...oldRequest, ...newRequest}
    store.commit('updateQuoteRequest', request)

    // add authenticity_token for csrf protection
    request.authenticity_token = document.querySelector('[name="csrf-token"]').content

    if (!!checkInDate.trim() && !!checkOutDate.trim()) {
      axios
        .put(url, request)
        .then(({ data: response }) => {
          this.initializeReservation(response)

          let bookNowData = response.breakdown

          let labelElement = document.querySelector('.js-BookNow__rates-per-night-label')

          if (labelElement) labelElement.innerHTML = `Night x ${bookNowData.days}`
          document.querySelector('.js-BookNow__rates-per-night-value').innerHTML = `$ ${bookNowData.base_rental.toFixed(2)}`


          if (bookNowData.heated_pool_fee) {
            document.querySelector('.js-BookNow__required-rates').classList.add('BookNow__reveal')
            document.querySelector('.js-BookNow__required-value').innerHTML = `$ ${bookNowData.heated_pool_fee.toFixed(2)}`
          }

          if (bookNowData.required_fees) {
            document.querySelectorAll('.fee-clone').forEach(el => el.remove())
            let requiredRatesElement = document.querySelector('.js-BookNow__required-rates');


            Array.from(bookNowData.required_fees).forEach((requiredFee) => {
              let requiredRatesElementClone = requiredRatesElement.cloneNode(true)
              requiredRatesElementClone.querySelector('.js-BookNow__label').innerHTML = requiredFee.name;
              requiredRatesElementClone.querySelector('.js-BookNow__required-value').innerHTML = `$ ${requiredFee.value.toFixed(2)}`
              requiredRatesElementClone.setAttribute('style', "display: flex;")
              requiredRatesElementClone.classList.add('fee-clone')

              requiredRatesElement.insertAdjacentElement("afterend", requiredRatesElementClone);
            })
          }

          document.querySelector('.js-BookNow__taxes-rates-value').innerHTML = `$ ${bookNowData.base_rental_tax.toFixed(2)}`
          if(bookNowData.coupon_discount > 0) {
            document.querySelector('.js-BookNow__discount-coupon-value').classList.add('BookNow__reveal')

            document.querySelector('.js-BookNow__discount-coupon-value').innerHTML = `- $ ${bookNowData.coupon_discount.toFixed(2)}`
          } else {
            document.querySelector('.js-BookNow__discount-coupon-value').classList.remove('BookNow__reveal')
          }

          document.querySelector('.js-BookNow__total-rates-value').innerHTML = `$ ${bookNowData.reservation_total.toFixed(2)}`
          document.querySelector('.js-BookNow__due-now-value').innerHTML = `$ ${bookNowData.due_today.toFixed(2)}`

          document.querySelector('.js-BookNow__details-down-arrow').classList.add('BookNow__reveal')

          document.querySelector('.js-BookNow__details-up-arrow').classList.add('BookNow__reveal')
          document.querySelector('.js-BookNow__details-down-arrow').classList.add('BookNow__hide')

          if (document.documentElement.clientWidth >= 1200) {
            document.querySelector('.js-BookNow__rates-container').classList.add('BookNow__reveal')
            // document.querySelector('.js-BookNow__gradient').setAttribute('style', 'z-index: 0;')
          }

          document.querySelectorAll('.js-choose-dates-submit-btn').forEach((chooseDatesButtonElement) => {
            chooseDatesButtonElement.innerHTML = "Select Dates"
            chooseDatesButtonElement.setAttribute('data-close-modal', true)
          })




          document.querySelector('.js-BookNow__details').classList.add('BookNow__clickable')
          document.querySelector('.js-BookNow__details-min-total').innerHTML = `<span class='subtitle'>$ ${this.numberWithCommas(bookNowData.reservation_total)} </span> Total`
          document.querySelector('.js-BookNow__details-min-total').classList.toggle('BookNow__arrow-up')

          let bookNowButtonElement = document.querySelector('.js-BookNow__button')
          bookNowButtonElement.innerHTML = "Book Now"
          bookNowButtonElement.setAttribute('data-book-now', true)
          this.closeCalendarModal()
          this.setQueryString()
        })
    }
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  setQueryString() {
    const arrive = document.getElementById('check_in_date_modal_visible').value;
    const depart = document.getElementById('check_out_date_modal_visible').value;
    let aY, aM, aD, dY, dM, dD;
    [aM, aD, aY] = arrive.split('/');
    [dM, dD, dY] = depart.split('/');
    let query = {
      checkin_date: `${aY}-${aM}-${aD}`,
      checkout_date: `${dY}-${dM}-${dD}`
    };
    query = queryString(query);
    const url = `${location.protocol}//${location.host}${location.pathname}?${query}`;
    if (history.pushState) {
      window.history.pushState({ path: url }, "", url);
    } else {
      window.location.href = url;
    }
  }

  initializeReservation(response) {
    // store response to vuex
    store.commit('initializeReservation', response)
  }

  isTabletLandscapeOrGreater() {
    return this.clientWidth >= this.tabletLandscape
  }

  isDesktopOrGreater() {
    return this.clientWidth >= this.desktop
  }

  monthIntegerToNameMap() {
    return {
      '0': 'january',
      '1': 'february',
      '2': 'march',
      '3': 'april',
      '4': 'may',
      '5': 'june',
      '6': 'july',
      '7': 'august',
      '8': 'september',
      '9': 'october',
      '10': 'november',
      '11': 'december'
    }
  }

  nextMonthNumber() {
    let nextMonthNumber = this.fp.currentMonth + 1

    if (nextMonthNumber === 12) {
      return 0
    } else {
      return nextMonthNumber
    }
  }


  ratesByCurrentMonth() {
    let monthInteger = this.currentMonthInteger();

    return this.ratesData.reduce((acc, rate) => {

      if (rate.month_integer === monthInteger) {
        acc.push(rate)
      }

      return acc;
    }, []).slice(0, this.availableCalendarDatesElements().length)
  }

  ratesByBothMonths() {
    let monthNames = this.currentlyDisplayedMonths();

    return this.ratesData.reduce((acc, rate) => {

      let visibleMonthRate = monthNames.find(name => rate.month_name === name.trim())

      if (!!visibleMonthRate) {
        acc.push(rate)
      }

      return acc;
    }, []).slice(0, this.availableCalendarDatesElements().length)
  }

  dayCreate(dObj, dStr, fp, dayElem) {
    if (dayElem.classList.contains("flatpickr-disabled")) return;
    let today = new Date();
    today = fp.formatDate(today, "Y-m-d");
    const humanDate = dayElem.getAttribute("aria-label");
    const formatted = fp.formatDate(new Date(humanDate), "Y-m-d");
    const day = this.ratesData.find(avail => avail.date === formatted);
    let text = "";

    const minDisabled = 2;

    /* Typecast `minDisabled` to a String for ie11 which lacks Object.includes() */
    if (`${minDisabled}`.includes(formatted)) {
      dayElem.classList.add("flatpickr-disabled");
    }

    if (today === formatted) {
      // eslint-disable-next-line no-param-reassign
      dayElem.innerHTML += `<span class="rate title">Today</span>`;
      dayElem.classList.add('is-today')
    }

    if (typeof day !== "undefined") {
      if (day.is_booked === 1 || day.has_error) {
        dayElem.classList.add('flatpickr-disabled')
      } else {
        text = day.text;
        // eslint-disable-next-line no-param-reassign
        dayElem.innerHTML += `<span class="rate">${text}</span>`;
      }
    }
  }
}
